import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Weekday Warriors" />
    <div className="Spread black-white full-width">
      <div className="TextContent content">
        <div style={{opacity: 1}}>
          <h1>Fitness nutrition for everyday athletes</h1>
          <p data-pm-context="[]">Most fitness nutrition is for bodybuilders. <br />So we are redesigning fitness nutrition for the rest of us.</p>
        </div>
      </div>
      <div className="image">
        <video
          width="100%"
          loop
          height="100%"
          autoPlay
          muted
          playsInline
        >
          <source src="https://www.datocms-assets.com/6493/1531783209-bghdv11.mp4" type="video/mp4" />
        </video>
        <div className="cover"></div>
      </div>
    </div>
    <div className="Spread white-brown half-text-left">
      <div className="TextContent content">
        <div style={{opacity: 1}}>
          <h2 className="p1">Eating right = 70% of exercise benefits</h2>
          <p>We often ignore diet to help reach our fitness goals, so we don't get the results we want. And&nbsp;most snack foods are not designed to truly support fitness.<br />That’s where Weekday Warriors comes in.&nbsp;</p>
        </div>
      </div>
      <div className="image">
        <div className="FadeImage">
          <img src="https://www.datocms-assets.com/6493/1531747858-pj-drink-1500x1800.jpg?auto=compress&amp;fm=jpg&amp;h=2880&amp;w=2400" alt="" style={{opacity: 1}} />
        </div>
      </div>
    </div>
    <div className="Spread white-brown full-width">
      <div className="TextContent content">
        <div style={{opacity: 1}}>
          <h2 data-pm-context="[]">Two foundational products</h2>
          <p data-pm-context="[]">A strong body is mostly achieved through resistance training: squatting, pressing, and lunging. But for every yin, there is a yang. Muscles benefit from resistance and stretching.&nbsp;It makes sense that Yoga is now becoming a key part to balancing gym workouts. We have created two foundational products with this view of modern fitness.</p>
        </div>
        <div className="actions shop-both-products">
          <a className="Btn dark-gold-white" href="https://drinkproteinjoe.com/">Shop Workouts</a>
          <a className="Btn dark-peach-white" href="https://www.drinkyogaflow.com/">Shop Yoga</a>
        </div>
      </div>
      <div className="image">
        <div className="FadeImage">
          <img src="https://www.datocms-assets.com/6493/1531813648-products1-3000x1800.jpg?auto=compress&amp;fm=jpg&amp;h=1440&amp;w=2400" alt="" style={{opacity: 1}} />
        </div>
      </div>
    </div>
    <div className="Spread light-peach-white half-text-left big-features">
      <div className="TextContent content">
        <div style={{opacity: 1}}>
          <ul data-pm-context="none">
            <li>
              <p>Natural ingredients</p>
            </li>
            <li>
              <p>Low sugars</p>
            </li>
            <li>
              <p>No fillers</p>
            </li>
            <li>
              <p>No chemicals</p>
            </li>
            <li>
              <p>Researched active ingredients</p>
            </li>
            <li>
              <p>Sourced globally</p>
            </li>
            <li>
              <p>Made in the USA</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="image">
        <div className="FadeImage">
          <img src="https://www.datocms-assets.com/6493/1531813586-peaches-1500x1800.jpg?auto=compress&amp;fm=jpg&amp;h=2880&amp;w=2400" alt="" style={{opacity: 1}} />
        </div>
      </div>
    </div>
    <div className="Spread white-brown full-width-text-left">
      <div className="TextContent content">
        <div style={{opacity: 1}}>
          <h2>Work hard (and workout harder)</h2>
          <p>Everyday athletes are multi-taskers. We expect a lot from what we put in our bodies. Quality in = quality out. A nourished body means better productivity during other parts of the day. 6am coffee? 3pm pick-me-up? We've got you covered.</p>
        </div>
        <div className="actions">
          <a className="Btn light-peach-white" href="https://www.drinkyogaflow.com/about">About Weekday Warriors</a>
        </div>
      </div>
      <div className="image">
        <div className="FadeImage">
          <img src="https://www.datocms-assets.com/6493/1531810861-girlpunch-3000x1800.jpg?auto=compress&amp;fm=jpg&amp;h=1440&amp;w=2400" alt="" style={{opacity: 1}} />
        </div>
      </div>
    </div>
    <div className="Spread white-brown full-width">
      <div className="TextContent content">
        <div style={{opacity: 1}}>
          <h2>Handy sizes, shipped to your door</h2>
          <p>Our products fit in your pocket, in sizes made to be torn into. No carrying big containers to the gym, or pre-making at home. Create your fix, wherever and whenever you need it.</p>
        </div>
        <div className="actions shop-both-products">
          <a className="Btn dark-gold-white" href="https://drinkproteinjoe.com/">Shop Workouts</a>
          <a className="Btn dark-peach-white" href="https://www.drinkyogaflow.com/">Shop Yoga</a>
        </div>
      </div>
      <div className="image">
        <div className="FadeImage">
          <img src="https://www.datocms-assets.com/6493/1531878628-packethands1-3000x1800.jpg?auto=compress&amp;fm=jpg&amp;h=1440&amp;w=2400" alt="" style={{opacity: 1}} />
        </div>
      </div>
    </div>
    <a className="BigCTA light-peach-white" href="https://www.drinkyogaflow.com/">
      <div className="TextContent">
        <div>
          <h2>Check out Yoga Flow➁<br />The world’s first yoga fitness drink</h2>
        </div>
        <div className="ctas">READ MORE→</div>
      </div>
    </a>
    <a className="BigCTA light-gold-white" href="https://drinkproteinjoe.com/">
      <div className="TextContent">
        <div>
          <h2>Check out Protein Joe➁<br />The all-in-one workout coffee</h2>
        </div>
        <div className="ctas">READ MORE→</div>
      </div>
    </a>
  </Layout>
)

export default IndexPage
